<template>
  <div class="auth-wrapper auth-v1 p-8">
    <div class="auth-inner py-2 mt-2">
      <div style="text-align: center" class="mb-2">
        <b-link class="brand-logo">
          <img src="@/assets/images/logo/logo.png" style="
              width: 11rem;
              height: auto;
              margin-bottom: 1rem;
            " alt="" />
        </b-link>

        <b-card-title class="mb-1">
          Focus on the properties that matter
        </b-card-title>
        <b-card-title class="mb-1" v-if="progressStatus">
          Redirecting to dashboard ....
        </b-card-title>
      </div>
      <div class="register-form" v-if="!progressStatus">
        <!-- finish-button-text="Submit" @on-complete="showConfirmationBox" -->
        <form-wizard color="#1F5A94" :title="null" :subtitle="null" shape="square" back-button-text="Previous"
          class="wizard-vertical" layout="vertical">

          <!-- Select Plan tab -->
          <tab-content title="Select Plan" icon="feather icon-file-text">
            <b-row>
              <b-col lg="12">
                <stripe-pricing-table :pricing-table-id="PRICING_TABLE_ID" :publishable-key="PUBLISHABLE_KEY"
                  client-reference-id="remap">

                </stripe-pricing-table>
              </b-col>
            </b-row>
          </tab-content>


        </form-wizard>
      </div>
    </div>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import Ripple from "vue-ripple-directive";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
// eslint-disable-next-line import/no-extraneous-dependencies
import useJwt from "@/auth/jwt/useJwt";

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BCard,
  BCardTitle,
  BLink,
  BCardText,
  BImg,
  BListGroup,
  BBadge,
  BButton,
  BFormCheckbox,
  BListGroupItem,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { relative } from "path";
import Vue from 'vue'
import config from '../../../config'

let stripe = Stripe(config.STRIPE_KEY);

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    BCard,
    BCardTitle,
    BLink,
    BCardText,
    // eslint-disable-next-line vue/no-unused-components
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BImg,
    BListGroup,
    BBadge,
    BButton,
    BFormCheckbox,
    BListGroupItem,
    // eslint-disable-next-line vue/no-unused-components
    AppCollapseItem,
    // eslint-disable-next-line vue/no-unused-components
    AppCollapse,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      API_URL: config.BASE_URL,
      STRIPE_PUBLISH_KEY: config.STRIPE_KEY,
      PRICING_TABLE_ID: config.PRICING_TABLE_ID,
      PUBLISHABLE_KEY: config.PUBLISHABLE_KEY,
      selectedContry: '',
      selectedLanguage: '',
      required,
      email,
      confirmationBox: false,
      progressStatus: false,
      phoneRegex: '^(\+?\(61\)|\(\+?61\)|\+?61|\(0[1-9]\)|0[1-9])?( ?-?[0-9]){7,9}$',
      form: {
        fullName: '',
        emailValue: '',
        phone: '',
        PasswordValue: '',
        billingAddress: '',
        // card: '',
        // expiry: '',
        // cvv: '',
        // cardName: '',
        status: '',
        planSelected: {
          id: '',
          selectedPlan: '',
          amount: '',
          meta: ''
        }
      },
      plans: {},
      monthlyPlanShow: true,
      card: {
        cvc: '',
        number: '',
        expiry: '',
      },

      //elements
      cardNumber: '',
      cardExpiry: '',
      cardCvc: '',
      phoneNumber: '',
      stripe: null,

      // errors
      stripeError: '',
      cardCvcError: '',
      cardExpiryError: '',
      cardNumberError: '',
      billingAddressError: '',
      phoneNumberError: '',

      loading: false,
    };
  },
  created() {
    this.$http.get(`${this.API_URL}/plans/fetchPlans`).then(res => {
      this.plans = res.data.response;
    })
  },
  mounted() {
    this.setUpStripe();
  },
  methods: {
    register(stripeObject) {
      if (this.confirmationBox) {
        if (this.form.plan === null || this.form.plan === "") {
          this.$bvToast.toast("Please Select any plan from Select Plan", {
            title: "Select Plan",
            variant: "danger",
            icon: "AlertOctagonIcon",
            solid: false,
            toaster: "b-toaster-top-center",
            appendToast: true,
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Form Submitted",
              icon: "EditIcon",
              variant: "success",
            },
          });

          this.progressStatus = true;

          useJwt
            .register({
              username: this.form.fullName,
              email: this.form.emailValue,
              password: this.form.PasswordValue,
              plan: this.form.planSelected,
              status: this.form.status,
              billingAddress: this.form.billingAddress,
              stripe: stripeObject,
            })
            .then((response) => {


              let res = response.data.response;

              if (response.data.success) {
                let res = response.data.response
                console.log(res.ability);
                useJwt.setToken(res.accessToken);
                useJwt.setRefreshToken(res.refreshToken);
                localStorage.setItem("userData", JSON.stringify(res));
                localStorage.setItem("isAdminSwitch", 'false');

                this.$ability.update(res.ability);

                this.progressStatus = false;
                this.$router.push("/home/dashboard");



              } else {
                this.progressStatus = false;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: `Error : ${res}`,
                    icon: "AlertTriangleIcon",
                    variant: "danger",
                  },
                });
              }

            })
            .catch((error) => {
              this.progressStatus = false;
              console.log(error);
              // this.$refs.registerForm.setErrors(error.response.data.error);
            });
        }
      } else {
        this.progressStatus = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Check Your Form and submit",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    tooglePlan() {
      if (this.form.status === "monthly") {
        this.monthlyPlanShow = true;
      } else {
        this.monthlyPlanShow = false;
      }
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validationFormCard() {
      // return new Promise((resolve, reject) => {
      //   this.$refs.cardRules.validate().then((success) => {
      //     if (success) {
      //       resolve(true);
      //     } else {
      //       reject();
      //     }
      //   });
      // });
    },
    validationFormPlan() {
      return new Promise((resolve, reject) => {
        this.$refs.planRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    showConfirmationBox() {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to submit form.", {
          title: "Please Confirm",
          size: "sm",
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          this.confirmationBox = value;
          // eslint-disable-next-line no-unused-expressions
          this.createToken()
          // ;
        });
    },
    activePlan(value) {
      this.form.planSelected.id = value.id;
      this.form.planSelected.name = value.name;
      this.form.planSelected.amount = value.price;
      this.form.planSelected.meta = value.meta;
      this.form.planSelected.monthlyPlanShow = value.monthlyPlanShow;
    },
    setUpStripe() {
      if (window.Stripe === undefined) {
        alert('Stripe V3 library not loaded!');
      } else {
        // const stripe = window.Stripe('pk_test_51LSaZrK7340zxZZkCIDPD7cUjjEH0kssavltmolsOiLRkximAdhzvXkVvPDMdIpzd0lAkkzxVUqgLmmqRAcFFRjD007KL8uwnG');
        this.stripe = stripe;

        const elements = stripe.elements();
        this.cardCvc = elements.create('cardCvc');
        this.cardExpiry = elements.create('cardExpiry');
        this.cardNumber = elements.create('cardNumber');

        this.cardCvc.mount('#card-cvc');
        this.cardExpiry.mount('#card-expiry');
        this.cardNumber.mount('#card-number');

        this.listenForErrors();
      }
    },
    listenForErrors() {
      const self = this;

      this.cardNumber.addEventListener('change', (event) => {
        self.toggleError(event);
        self.cardNumberError = ''
        self.card.number = event.complete ? true : false
      });

      this.cardExpiry.addEventListener('change', (event) => {
        self.toggleError(event);
        self.cardExpiryError = ''
        self.card.expiry = event.complete ? true : false
      });

      this.cardCvc.addEventListener('change', (event) => {
        self.toggleError(event);
        self.cardCvcError = ''
        self.card.cvc = event.complete ? true : false
      });
    },
    toggleError(event) {
      if (event.error) {
        this.stripeError = event.error.message;
      } else {
        this.stripeError = '';
      }
    },
    submitFormToCreateToken() {
      this.clearCardErrors();
      let valid = true;

      if (!this.card.number) {
        valid = false;
        this.cardNumberError = "Card Number is Required";
      }
      if (!this.card.cvc) {
        valid = false;
        this.cardCvcError = "CVC is Required";
      }
      if (!this.card.expiry) {
        valid = false;
        this.cardExpiryError = "Month is Required";
      }
      if (this.stripeError) {
        valid = false;
      }
      if (valid) {
        this.createToken()
      }
    },
    createToken() {
      this.stripe.createToken(this.cardNumber).then((result) => {
        if (result.error) {
          this.stripeError = result.error.message;
        } else {
          this.register(result)
        }
      })
    },
    clearElementsInputs() {
      this.cardCvc.clear()
      this.cardExpiry.clear()
      this.cardNumber.clear()
    },
    clearCardErrors() {
      this.stripeError = ''
      this.cardCvcError = ''
      this.cardExpiryError = ''
      this.cardNumberError = ''
    },
    reset() {
      this.clearElementsInputs()
      this.clearCardErrors()
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
// @import '@core/scss/vue/libs/vue-select.scss';
@import "@core/scss/vue/pages/page-pricing.scss";

/* ----------- Non-Retina Screens ----------- */
@media screen and (min-device-width: 1200px) and (max-device-width: 2600px) and (-webkit-min-device-pixel-ratio: 1) {
  .register-form {
    margin-left: 10rem;
    margin-right: 10rem;
    align-items: center;
  }
}

/* ----------- Retina Screens ----------- */
@media screen and (min-device-width: 1200px) and (max-device-width: 2600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
  .register-form {
    margin-left: 10rem;
    margin-right: 10rem;
    align-items: center;
  }
}

/* ----------- Non-Retina Screens ----------- */
@media screen and (min-device-width: 1000px) and (max-device-width: 1200px) and (-webkit-min-device-pixel-ratio: 1) {
  .register-form {
    margin-left: 5rem;
    margin-right: 5rem;
    align-items: center;
  }
}

/* ----------- Retina Screens ----------- */
@media screen and (min-device-width: 1000px) and (max-device-width: 1200px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
  .register-form {
    margin-left: 5rem;
    margin-right: 5rem;
    align-items: center;
  }
}

/* ----------- Non-Retina Screens ----------- */
@media screen and (min-device-width: 300px) and (max-device-width: 1000px) and (-webkit-min-device-pixel-ratio: 1) {
  .register-form {
    margin-left: 1rem;
    margin-right: 1rem;
    align-items: center;
  }
}

/* ----------- Retina Screens ----------- */
@media screen and (min-device-width: 300px) and (max-device-width: 1000px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
  .register-form {
    margin-left: 1rem;
    margin-right: 1rem;
    align-items: center;
  }
}

.stripeError {
  color: red;
  font-style: italic;
}

.card-body {
  min-height: 475px !important;
}

[dir=ltr] .vertical.wizard-vertical.vue-form-wizard .wizard-card-footer {
  margin-left: 261px;
  border-left: 1px solid rgba(34, 41, 47, 0.08);
}

.wizard-btn {
  display: none !important;
}
</style>
